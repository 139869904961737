import React from "react";
import { Row, Col } from "react-grid-system";
// // import { InView } from "react-intersection-observer";
import { Zoom, Fade, LightSpeed } from "react-reveal";
import Image from "../components/Image";
import info from "../data/education";
import logo from "../assets/images/hku-logo.png";
import "../styles/education.css";

function Education() {
  // const [show, setShow] = useState(false);
  const show = true;

  return (
    // <InView onChange={(inView) => setShow(inView)}>
    <div className={"education-section"} id={"education"}>
      <Row style={{ width: "100%", height: "100%" }}>
        <Col xs={12}>
          <h1>
            {show && (
              <LightSpeed left cascade>
                EDUCATION
              </LightSpeed>
            )}
          </h1>
        </Col>
        <Col xs={12} sm={12} md={12} lg={2} xl={3} xxl={4}>
          {show && (
            <div className={"education-shape-container"}>
              <Zoom duration={1000}>
                <span />
              </Zoom>
              <Zoom duration={1000} delay={300}>
                <span />
              </Zoom>
            </div>
          )}
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={9}
          xxl={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col xs={3}>
              {show && (
                <Fade delay={1000}>
                  <Image
                    src={logo}
                    style={{
                      width: "100%",
                      maxWidth: "280px",
                      paddingTop: "60px",
                    }}
                  />
                </Fade>
              )}
            </Col>
            <Col xs={9}>
              {show && (
                <Fade delay={700}>
                  <div className={"education-description"}>
                    <h2>{info.title}</h2>
                    <p>{info.subtitle}</p>
                    <p>{info.location}</p>
                    <p>{info.duration}</p>
                  </div>
                </Fade>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
    // </InView>
  );
}

export default Education;
