import React from "react";
import { Row, Col } from "react-grid-system";
// // import { InView } from "react-intersection-observer";
import { Bounce, Fade } from "react-reveal";
import PortfolioCard from "../components/PortfolioCard";
import info from "../data/portfolio";
import "../styles/portfolio.css";

function Portfolio() {
  // const [show, setShow] = useState(false);
  const show = true;

  return (
    // <InView onChange={(inView) => setShow(inView)}>
    <div className={"portfolio-section"} id={"portfolio"}>
      {
        <h1>
          {show && (
            <Bounce top cascade>
              PORTFOLIO
            </Bounce>
          )}
        </h1>
      }
      <Row>
        {info.map((list, index) => (
          <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={3} key={index}>
            <Fade bottom delay={index * 300} when={show}>
              <PortfolioCard
                img={list.url}
                title={list.title}
                desc={list.desc}
                link={list.link}
              />
            </Fade>
          </Col>
        ))}
      </Row>
    </div>
    // </InView>
  );
}

export default Portfolio;
