import SupertestLogo from "../assets/images/supertest-logo.png";
import OwaspZapLogo from "../assets/images/owasp-zap-logo.png";
import CORSLogo from "../assets/images/cors-logo.png";

const info = [
  {
    title: "Programming Languages",
    skills: [
      ["JavaScript", "logos:javascript"],
      ["TypeScript", "logos:typescript-icon"],
      ["Python", "logos:python"],
      ["GoLang", "skill-icons:golang"],
      ["Java", "devicon:java-wordmark"],
      ["PHP", "logos:php"],
    ],
  },
  {
    title: "Frontend Development",
    skills: [
      ["React.js", "devicon:react-wordmark"],
      ["Next.js", "cib:next-js"],
      ["React Native", "tabler:brand-react-native"],
      ["Vue.js", "logos:vue"],
      ["MUI", "devicon:materialui"],
      ["Tailwind CSS", "devicon:tailwindcss"],
    ],
  },
  {
    title: "Backend Development",
    skills: [
      ["Express.js", "skill-icons:expressjs-dark"],
      ["Nest.js", "skill-icons:nestjs-light"],
      ["Fast API", "logos:fastapi-icon"],
      ["Django", "logos:django-icon"],
      ["Flask", "logos:flask"],
      ["Gin-Gonic", "devicon:go"],
    ],
  },
  {
    title: "CMS Development",
    skills: [
      ["Sanity", "devicon:sanity"],
      ["Strapi", "logos:strapi-icon"],
      ["WordPress", "skill-icons:wordpress"],
      ["Shopify", "logos:shopify"],
      ["Contentful", "logos:contentful"],
      ["Netlify CMS", "skill-icons:netlify-light"],
    ],
  },
  {
    title: "Database Management",
    skills: [
      ["MongoDB", "devicon:mongodb-wordmark"],
      ["Redis", "devicon:redis-wordmark"],
      ["MySQL", "logos:mysql"],
      ["PostgreSQL", "devicon:postgresql-wordmark"],
      ["SQLite", "skill-icons:sqlite"],
      ["Neo4j", "devicon:neo4j"],
      ["Elasticsearch", "logos:elasticsearch"],
      ["Apache Solr", "logos:solr"],
    ],
  },
  {
    title: "DevOps Engineering Skills",
    skills: [
      ["Git", "logos:git"],
      ["Jira", "devicon:jira-wordmark"],
      ["Jenkins", "logos:jenkins"],
      ["Amazon Web Service", "skill-icons:aws-dark"],
      ["Google Cloud Platform", "skill-icons:gcp-light"],
      ["Docker", "devicon:docker-wordmark"],
      ["Kubernetes", "logos:kubernetes"],
      ["CI/CD Pipeline", "clarity:ci-cd-line"],
    ],
  },
  {
    title: "API & Security Skills",
    skills: [
      ["RESTful API Design", "dashicons:rest-api"],
      ["GraphQL", "vscode-icons:file-type-graphql"],
      ["JSON Web Tokens (JWT)", "logos:jwt-icon"],
      ["OAuth", "logos:oauth"],
      ["OpenID Connect", "fa:openid"],
      ["HTTPS & SSL/TLS & PGP", "fa:expeditedssl"],
      ["XSS & CSRF & SQL injection", "material-symbols:security"],
      ["CORS", CORSLogo],
    ],
  },
  {
    title: "Testing Frameworks",
    skills: [
      ["Jest", "logos:jest"],
      ["Mocha", "logos:mocha"],
      ["Chai", "logos:chai"],
      ["Selenium", "logos:selenium"],
      ["Puppeteer", "logos:puppeteer"],
      ["Cypress", "logos:cypress-icon"],
      ["Supertest", SupertestLogo],
      ["OWASP ZAP", OwaspZapLogo],
    ],
  },
];

export default info;
