import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import Header from "./layout/Header";
import About from "./layout/About";
import Skills from "./layout/Skills";
import Portfolio from "./layout/Portfolio";
import Experience from "./layout/Experience";
import Education from "./layout/Education";
import Contact from "./layout/Contact";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import DoorEffect from "./components/DoorEffect";
import Loader from "./components/Loader";
import store from "./store";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", function () {
      setIsLoading(false);
    });
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Provider store={store}>
      <div>
        <DoorEffect />
        <Navbar />
        <Header />
        <About />
        <Skills />
        <Portfolio />
        <Experience />
        <Education />
        <Contact />
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
