import React from "react";
import info from "../data/footer";
import "../styles/footer.css";

function Footer() {
  return (
    <div className={"footer"} id={"footer"}>
      <p>🐯 {info.copyright} 🐯</p>
      {/* <p>🌏 {info.location} 🌎</p> */}
    </div>
  );
}

export default Footer;
