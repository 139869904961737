const info = {
  title: "The University of Hong Kong (HKU)",
  location: "Pok Fu Lam, Hong Kong",
  subtitle: "Bachelor's in Computer Science",
  logo_path: "hku-logo.png",
  duration: "04/2011 - 03/2015",
  descriptions: [
    "Courses related to Computer Programming and Data Structures, Algorithms, Human-Computer Interaction and User Experience Design, Software Engineering, Development Methodologies, etc. have been taken.",
    "Apart from this, courses on Database Systems and Data Management, Computer Networks and Distributed Systems, Artificial Intelligence, and Machine Learning have been done.",
  ],
  website_link: "https://hku.hk",
};

export default info;
