import React from "react";
// import Typewriter from "typewriter-effect";
import Carousel from "../components/Carousel";
import info from "../data/home";
import "../styles/header.css";

function Header() {
  return (
    <div className="slider" id={"home"}>
      <div className={"slide-day"}></div>
      <div className="slide-night"></div>

      <span className={"slide-title"}>
        <center>
          <h1>
            {info.fullName}
            {/* <span>{info.firstName}</span>
            <span>{info.lastName}</span> */}
          </h1>
        </center>
        <hr />
        <Carousel headlines={info.headlines} />
        {/* <span className="headline">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter
                .pauseFor(500)
                .typeString(`<span>${info.headlines[0]}</span>`)
                .pauseFor(300)
                .deleteChars(24)
                .typeString(`<span>${info.headlines[1]}</span>`)
                .pauseFor(300)
                .deleteChars(12)
                .typeString(`<span>${info.headlines[2]}</span>`)
                .pauseFor(300)
                .deleteChars(17)
                .start();
            }}
          />
        </span> */}
      </span>
    </div>
  );
}

export default Header;
