import emailjs from "@emailjs/browser";
import info from "../data/contact";

export const COMMENT_REQUEST = "[COMMENT] REQUEST";
export const COMMENT_SUCCESS = "[COMMENT] SUCCESS";
export const COMMENT_FAILED = "[COMMENT] FAILED";
export const COMMENT_STATE_REFRESH = "[COMMENT] REFRESH";

export const sendComment =
  ({ name, email, comment, mailTo }) =>
  (dispatch) => {
    dispatch({ type: COMMENT_REQUEST });
    return emailjs
      .send(
        info.emailjsConfig.serviceId,
        info.emailjsConfig.templateId,
        {
          from_name: name,
          to_name: mailTo,
          message: comment,
          reply_to: email,
        },
        info.emailjsConfig.publicKey
      )
      .then((response) => {
        console.log("💖", response.status, response.text);
        dispatch({ type: COMMENT_SUCCESS });
      })
      .catch((err) => {
        console.log("💥", err);
        dispatch({ type: COMMENT_FAILED });
      });
  };

export const setStateRefresh = () => (dispatch) => {
  dispatch({ type: COMMENT_STATE_REFRESH });
};
