import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-grid-system";
// // import { InView } from "react-intersection-observer";
import { Bounce, Fade } from "react-reveal";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import { sendComment, setStateRefresh } from "../store/comment.action";
import Button from "../components/Button";
import info from "../data/contact";
import "../styles/contact.css";

function Contact() {
  // const [show, setShow] = useState(false);
  const show = true;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();
  const { isLoading, success } = useSelector((state) => state.comment);

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = {
      name,
      email,
      comment,
      mailTo: info.mailTo,
    };

    setName("");
    setEmail("");
    setComment("");

    dispatch(sendComment(submitData))
      .then(() => {})
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Sorry, there was an error sending your message. Please try again with email icon(📧) of footer.",
        });
      });
  };

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        text: "Your message was sent successfully!",
      });
    } else if (success === false) {
      Swal.fire({
        icon: "error",
        text: "Sorry, there was an error sending your message. Please try again with email icon(📧) of footer.",
      });
    }
    dispatch(setStateRefresh());
  }, [success, dispatch]);

  return (
    // <InView onChange={(inView) => setShow(inView)}>
    <div className={"contact-image"} id={"contact"}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={4}>
          {show && (
            <Bounce>
              <div className="contact-info">
                <h1 className="contact-info-title">CONTACT ME</h1>
                <div className="contact-info-icons">
                  <a href={info.email.link} target="new">
                    <Icon icon="fxemoji:email" />
                  </a>
                  {/* <a href={info.linkedin.link} target="new">
                    <Icon icon="devicon:linkedin" />
                  </a> */}
                  <a href={info.whatsapp.link} target="new">
                    <Icon icon="logos:whatsapp-icon" />
                  </a>
                  <a href={info.skype.link} target="new">
                    <Icon icon="logos:skype" />
                  </a>
                  <a href={info.telegram.link} target="new">
                    <Icon icon="logos:telegram" />
                  </a>
                  <a href={info.discord.link} target="new">
                    <Icon icon="skill-icons:discord" />
                  </a>
                  <a href={info.github.link} target="new">
                    <Icon icon="skill-icons:github-light" />
                  </a>
                </div>
              </div>
            </Bounce>
          )}
        </Col>

        <Col xs={12} sm={12} md={12} lg={6} xl={7} xxl={8}>
          {show && (
            <Fade delay={300}>
              <form className="contact-form" onSubmit={handleSubmit}>
                <input
                  name={"name"}
                  placeholder={"Full Name"}
                  className={"contact-input"}
                  value={name}
                  required
                  onChange={({ target: { value } }) => setName(value)}
                />

                <input
                  name={"email"}
                  placeholder={"Email"}
                  className={"contact-input"}
                  value={email}
                  required
                  onChange={({ target: { value } }) => setEmail(value)}
                />

                <textarea
                  name={"comment"}
                  placeholder={"Type here..."}
                  className={"contact-textarea"}
                  value={comment}
                  required
                  onChange={({ target: { value } }) => setComment(value)}
                />

                <Button width={"10vw"} height={"60px"}>
                  {isLoading ? (
                    <Icon icon="eos-icons:bubble-loading" />
                  ) : (
                    <Icon icon="fa:send-o" />
                  )}
                  &nbsp;SEND
                </Button>
              </form>
            </Fade>
          )}
        </Col>
      </Row>
    </div>
    // </InView>
  );
}

export default Contact;
