import React from "react";
import { Row, Col } from "react-grid-system";
// // import { InView } from "react-intersection-observer";
import { Slide, Zoom } from "react-reveal";
import { Tooltip } from "react-tooltip-z";
import { Icon } from "@iconify/react";
import SkillCard from "../components/SkillCard";
// import Image from "../components/Image";
import info from "../data/skills";
import "../styles/skills.css";

function Skills() {
  // const [show, setShow] = useState(false);
  const show = true;

  return (
    // <InView onChange={(inView) => (inView ? setShow(true) : setShow(false))}>
    <div className={"skill-section"} id={"skills"}>
      {show && (
        <h1>
          <Zoom right cascade>
            MY SKILLS
          </Zoom>
        </h1>
      )}

      <Row style={{ display: "flex" }}>
        {info.map((skillSet) => (
          <Col xs={12} md={6} key={skillSet.title}>
            <Slide left when={show}>
              <SkillCard color={"#4caf50"}>
                <h1>{skillSet.title}</h1>
                {skillSet.skills.map((icon) => (
                  <Tooltip
                    tooltipContent={icon[0]}
                    key={icon[0]}
                    style={{
                      width: "80px",
                      height: "80px",
                      margin: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {/* <Image key={index} alt={'skill'} src={`https://skillicons.dev/icons?i=${skillname}`}/> */}
                    {!(
                      icon[1].startsWith("/static/media/") ||
                      icon[1].startsWith("data:image/")
                    ) ? (
                      <Icon
                        icon={icon[1]}
                        style={{
                          width: "80px",
                          height: "80px",
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      />
                    ) : (
                      <img
                        src={icon[1]}
                        style={{
                          width: "80px",
                          height: "80px",
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        alt=""
                      />
                    )}
                  </Tooltip>
                ))}
              </SkillCard>
            </Slide>
          </Col>
        ))}
      </Row>
    </div>
    // </InView>
  );
}

export default Skills;
