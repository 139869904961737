const info = [
  {
    position: "Full Stack Developer",
    company: "Scopic (Remote)",
    location: "Rutland, Massachusetts, United States",
    company_url: "https://scopicsoftware.com",
    duration: "03/2021 - 03/2023",
    description: [
      "Developed and maintained 5 large-scale web applications using the MERN Stack, MEVN Stack, MEAN Stack, and Django Stack.",
      "Delivered multiple projects on time and budget, including a new user authentication system that improved security by 70% and a search algorithm that improved accuracy by 20%.",
      "Led a team of 5 developers to adopt agile practices, resulting in a 30% increase in productivity.",
      "Implemented a new code review process that reduced defects by 20%.",
    ],
  },
  {
    position: "Frontend Developer",
    company: "PCCW Solutions",
    location: "Kowloon City, Kowloon, Hong Kong",
    company_url: "https://www.pccwsolutions.com",
    duration: "09/2017 - 03/2021",
    description: [
      "Established and preserved user-centric web interfaces for 8 e-commerce websites with Next.js, React, and Vue.js.",
      "Collaborated with more than 10 designers and engineers to create an intuitive and visually appealing user experience.",
      "Launched the new web interface and marketing campaign, resulting in a 10% increase in user engagement and a 15% increase in active users.",
      "Reduced customer support costs by 30% by designing and implementing a chatbot.",
    ],
  },
  {
    position: "Backend Developer",
    company: "Fimmick",
    location: "Kowloon City, Kowloon, Hong Kong",
    company_url: "https://fimmick.com",
    duration: "03/2015 - 09/2017",
    description: [
      "Built and sustained a scalable backend system for 4 social media platforms using Express, Python, Node.js, Elasticsearch, and Restful APIs.",
      "Optimized database queries to improve web application performance by 20%.",
      "Worked with engineers to design and implement a data storage system that improved performance by 30%.",
      "Resolved server issues within 1 hour to maintain constant availability of social media platforms for users.",
    ],
  },
];

export default info;
