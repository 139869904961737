const info = [
  {
    url: "/assets/images/portfolio/kitsumon.com.png",
    title: "Kitsumon",
    desc: "The world of Kitsumon is an amazing place where players collect, trade and even breed creatures known as Kitsumon. Kitsumon is a free to play online multiplayer game powered by NFT blockchain technology on Polygon.",
    link: "https://kitsumon.com",
  },
  {
    url: "/assets/images/portfolio/phoenix-gambling.com.png",
    title: "Phoenix",
    desc: "Buy casino platform and game development / Win5X. Open your casino with our software with more than 25 games & slots. Official Win5X developer.",
    link: "https://phoenix-gambling.com",
  },
  {
    url: "/assets/images/portfolio/kingflokiworld.com.png",
    title: "King Floki",
    desc: "An awesome game in the metaverse, brought to you through the King ecosystem. In-game NFTs. Play to win.",
    link: "https://kingflokiworld.com",
  },
  {
    url: "/assets/images/portfolio/www.metabountyhunters.io.png",
    title: "Meta Bounty Hunters",
    desc: "Introducing the Meta Bounty Hunters, a strong, fearless collection of 8,888 Bounty Hunters set to protect, form alliances, and take over the NFT Galaxy!",
    link: "https://www.metabountyhunters.io",
  },
  {
    url: "/assets/images/portfolio/furio.io.png",
    title: "Furio",
    desc: "Furio offers a deflationary up to 2.5% daily ROI system. Paired with USDC it is completely unaffected by the volatility of the Crypto market.",
    link: "https://furio.io",
  },
  {
    url: "/assets/images/portfolio/brokenships.com.png",
    title: "Museum of Broken Relationships",
    desc: "Where memories of broken hearts live.",
    link: "https://brokenships.com",
  },
  {
    url: "/assets/images/portfolio/www.babyvegas.com.au.png",
    title: "Baby Vegas",
    desc: "Shop for Children's Gifts Online. Offers a range of baby clothes, toys, and accessories.",
    link: "https://www.babyvegas.com.au",
  },
  {
    url: "/assets/images/portfolio/alphacoders.com.png",
    title: "Alpha Coders",
    desc: "Alpha Coders - Your Source For Wallpapers, Art, Photography, Gifs, and More!",
    link: "https://alphacoders.com",
  },
  {
    url: "/assets/images/portfolio/botb.com.png",
    title: "BOTB",
    desc: "BOTB (Best of the Best plc) is the number one car competition company, making dreams come true since 1999.",
    link: "https://botb.com",
  },
  {
    url: "/assets/images/portfolio/www.collegeadvisor.com.png",
    title: "College Advisor",
    desc: "An online platform for finding and connecting with college admissions counselors.",
    link: "https://www.collegeadvisor.com",
  },
  {
    url: "/assets/images/portfolio/streamer.com.au.png",
    title: "Streamer",
    desc: "See it on Streamer, where we give you the tools to watch or set up your own live streaming program. Currently featuring AFL, hockey, surfing, volleyball and more.",
    link: "https://streamer.com.au",
  },
  {
    url: "/assets/images/portfolio/myntra.com.png",
    title: "Online Shopping - Myntra",
    desc: "Online Shopping Site for Fashion & Lifestyle in India. India's Fashion Expert brings you a variety of footwear, Clothing, Accessories and lifestyle products for women & men. Best Online Fashion Store *COD *Easy returns and exchanges.",
    link: "https://myntra.com",
  },
];

export default info;
