import React from "react";

function Carousel(props) {
  return (
    <div className={"carousel"} {...props}>
      I'm a&nbsp;
      <div>
        <span>
          <center></center>
        </span>
        <span>
          <center></center>
        </span>
        {props.headlines.map((headline) => (
          <p key={headline}>{headline}</p>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
