import React, { useEffect, useState } from "react";
import { Slide } from "react-reveal";
import info from "../data/home";
import logo from "../assets/images/tiger.jpg";
import "../styles/navbar.css";

function Navbar() {
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () =>
      setScrollHeight(window.pageYOffset)
    );
    if (scrollHeight > window.innerHeight / 3) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [scrollHeight]);

  return (
    <div>
      <Slide duration={500} top when={isScrolled}>
        <div className={"navbar-scrolled"}>
          <a href={"/"} style={{ display: "flex" }}>
            <div>
              <img
                src={logo}
                alt={"logo"}
                style={{
                  height: "40px",
                  lineHeight: "40px",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="desktop">
              <span
                style={{
                  fontFamily: "Agustina Regular",
                  fontSize: "24px",
                  fontWeight: "bold",
                  height: "40px",
                  lineHeight: "40px",
                  paddingLeft: "16px",
                }}
              >
                {info.fullName}
              </span>
            </div>
          </a>
          <div style={{ float: "right" }}>
            <div style={{ float: "right" }}>
              <a href={"#home"}>HOME</a>
              <a href={"#about"}>ABOUT</a>
              <a href={"#skills"}>SKILLS</a>
              <a href={"#portfolio"}>PORTFOLIO</a>
              <br className="mobile" />
              <br className="mobile" />
              <a href={"#experience"}>EXPERIENCE</a>
              <a href={"#education"}>EDUCATION</a>
              <a href={"#contact"}>CONTACT</a>
            </div>
          </div>
        </div>
      </Slide>

      <div className={"navbar"}>
        <a href={"/"} style={{ display: "flex" }}>
          <div>
            <img
              src={logo}
              alt={"logo"}
              style={{
                height: "40px",
                lineHeight: "40px",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="desktop">
            <span
              style={{
                fontFamily: "Agustina Regular",
                fontSize: "24px",
                fontWeight: "bold",
                height: "40px",
                lineHeight: "40px",
                paddingLeft: "16px",
              }}
            >
              {info.fullName}
            </span>
          </div>
        </a>
        <div style={{ float: "right" }}>
          <div style={{ float: "right" }}>
            <a href={"#home"}>HOME</a>
            <a href={"#about"}>ABOUT</a>
            <a href={"#skills"}>SKILLS</a>
            <a href={"#portfolio"}>PORTFOLIO</a>
            <br className="mobile" />
            <br className="mobile" />
            <a href={"#experience"}>EXPERIENCE</a>
            <a href={"#education"}>EDUCATION</a>
            <a href={"#footer"}>CONTACT</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
