import React from "react";
// // import { InView } from "react-intersection-observer";
import { Fade } from "react-reveal";
import ExperiencePart from "../components/ExperiencePart";
import info from "../data/experience";
import "../styles/experience.css";

function Experience() {
  // const [show, setShow] = useState(false);
  const show = true;

  return (
    // <InView onChange={(inView) => setShow(inView)}>
    <div className={"experience-section"} id={"experience"}>
      <h1>
        {show && (
          <Fade right cascade>
            EXPERIENCE
          </Fade>
        )}
      </h1>

      {info.map((exp, index) => (
        <ExperiencePart
          key={index}
          duration={exp.duration}
          position={exp.position}
          company={exp.company}
          location={exp.location}
          url={exp.company_url}
          desc={exp.description}
        />
      ))}
    </div>
    // </InView>
  );
}

export default Experience;
