const info = {
  summary: [
    "Full Stack Developer with 8+ years of experience developing and deploying web applications.",
    "Proven ability to deliver high-quality, secure, and reliable web applications independently and as part of a team.",
    "Strong knowledge of DevOps methodologies and tools, including Git, Jira, Jenkins, and Docker.",
  ],
  resume:
    "https://drive.google.com/file/d/1JKyroTLiOKpWHNClExU3coTXd4uMhOfB/view",
};

export default info;
