const info = {
  mailTo: "Tyrese Jin",
  emailjsConfig: {
    serviceId: "tyrese-gmail-service",
    templateId: "tyrese-email-template",
    publicKey: "YHPBWNTlBVyTPuLGp",
  },
  email: {
    content: "TyreseJin3915@gmail.com",
    link: "mailto:tyresejin3915@gmail.com",
  },
  skype: {
    content: "Friendly Tyrese",
    link: "skype:live:.cid.470ed1c782c17d7f",
  },
  telegram: {
    content: "Friendly Tyrese",
    link: "https://t.me/tyrese3915",
  },
  discord: {
    content: "Friendly Tyrese",
    link: "https://discord.gg/WmeWnY7QmP",
  },
  github: {
    content: "Tyrese-FullStackGenius",
    link: "https://github.com/Tyrese-FullStackGenius",
  },
  linkedin: {
    content: "@TyreseJin",
    link: "https://www.linkedin.com/in/TyreseJin/",
  },
  whatsapp: {
    content: "+1 (415) 992-7666",
    link: "https://wa.me/14159927666",
  },
};

export default info;
