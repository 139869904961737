import React from "react";
import { Row, Col } from "react-grid-system";
import { Zoom, Fade, Bounce } from "react-reveal";
// // import { InView } from "react-intersection-observer";
import Button from "../components/Button";
import info from "../data/about";
import "../styles/about.css";

function About() {
  // const [show, setShow] = useState(false);
  const show = true;

  return (
    // <InView onChange={(inView) => setShow(inView)}>
    <div className={"about-section"} id={"about"}>
      <Row style={{ width: "100%", height: "100%" }}>
        <Col xs={12} sm={4}>
          {show && (
            <div className={"about-shape-container"}>
              <Zoom duration={1000}>
                <span />
              </Zoom>
              <Zoom duration={1000} delay={300}>
                <span />
              </Zoom>
            </div>
          )}
        </Col>

        <Col
          xs={12}
          sm={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {show && (
            <Fade delay={700}>
              {info.summary.map((desc) => (
                <div className={"about-description"} key={desc}>
                  {desc}
                </div>
              ))}
            </Fade>
          )}

          {show && (
            <Bounce duration={500} delay={1000}>
              <a href={info.resume} target="new">
                <Button width={"20vw"} height={"60px"}>
                  View Resume
                </Button>
              </a>
            </Bounce>
          )}
        </Col>
      </Row>
    </div>
    // </InView>
  );
}

export default About;
